import React from 'react';
import { graphql } from 'gatsby';
import Container from '~components/common/Container';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import BlogCard from '~components/common/BlogCard';
import GridThree from '~components/common/GridThree';

function Kurse({ location, data }) {
  const links = [
    {
      titel: 'Blog',
      image: data.bildBlog,
      link: 'blog/',
      text: 'Artikel zum entspannter werden und für mehr Gelassenheit.'
    },
    {
      titel: 'Podcast',
      image: data.bildPodcast,
      link: 'podcast/',
      text: 'Entspannungshelden ist dein wöchentlicher Arschtritt für mehr Lebensqualität.'
    },
    {
      titel: 'FAQ',
      image: data.bildFaq,
      link: 'faq/',
      text: 'Eure häufigsten Fragen.'
    }
  ];

  return (
    <Layout
      location={location}
      title="✓ Wissen zu den Themen Entspannung und Achtsamkeit"
      desc="Im Bereich Wissen findest du unseren Blog und Podcast. In regelmäßigen Abständen posten wir verschiedene Wissensinhalte für dich."
      active="Wissen"
    >
      <PageHeader title="Wissen" breadcrumb={[{ title: 'Wissen' }]} />

      <Container noMargin className="my-8">
        <GridThree itemsStart>
          {links.map((entry, i) => {
            return <BlogCard title={entry.titel} image={entry.image} link={`/${entry.link}`} text={entry.text} key={`wissen-link-${i}`} />;
          })}
        </GridThree>
      </Container>
    </Layout>
  );
}

export default Kurse;

export const query = graphql`
  query {
    bildBlog: file(relativePath: { eq: "pages/wissen/blog.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    bildPodcast: file(relativePath: { eq: "pages/wissen/podcast.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    bildFaq: file(relativePath: { eq: "pages/wissen/faq.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
